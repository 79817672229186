import AnuraWebContainer from '../containers/AnuraWeb'

const AnuraWeb = () => {

  return (
    <>
      <AnuraWebContainer />
    </>
  );
}

export default AnuraWeb;

